<template>
  <v-navigation-drawer
    :value="isAddNewItemSidebarActive"
    :permanent="isAddNewItemSidebarActive"
    :right="!$vuetify.rtl"
    :width="'85%'"
    app
    @input="val => $emit('update:is-add-new-item-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Item</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-item-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                :rules="[validators.required]"
                v-model="itemData.selectedCategory"
                class="mb-6"
                placeholder="Category"
                :items="categoriesOptions"
                item-text="title"
                item-value="value"
                outlined
                dense
                clearable
                hide-details
                @input="setCategory()"
              ></v-select>

              <v-select
                v-if="categoryChosen"
                v-model="itemData.category_id"
                class="mb-6"
                :rules="[validators.required]"
                placeholder="Sub-Category"
                :items="subCategories"
                item-text="name_en"
                item-value="id"
                outlined
                dense
                clearable
                hide-details
                @input="subCatChange()"
              ></v-select>

              <v-text-field
                v-if="categoryChosen"
                v-model="itemData.name_en"
                :rules="[validators.required]"
                outlined
                dense
                label="Name (En)"
                placeholder="Mansaf"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>

              <v-text-field
                v-if="categoryChosen"
                v-model="itemData.name_ar"
                :rules="[validators.required]"
                outlined
                dense
                label="Name (Ar)"
                placeholder="منسف"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>

              <v-select
                v-if="categoryChosen && !isAddOns"
                v-model="itemData.chief_id"
                :rules="[validators.required]"
                class="mb-6"
                placeholder="Chef Name"
                :items="chefs"
                item-text="name"
                item-value="id"
                outlined
                dense
                clearable
                hide-details
              ></v-select>

              <v-text-field
                v-if="categoryChosen && !itemData.size.length"
                v-model="itemData.price"
                :rules="[validators.decimal_integer_Validator, calculatePointsMessage]"
                outlined
                dense
                label="Price in JOD"
                placeholder="5 JOD"
                hide-details="auto"
                class="mb-6"
                @change.capture="calculatePoints()"
              ></v-text-field>

              <v-text-field
                v-if="categoryChosenMeal"
                v-model="itemData.quantity"
                :rules="[validators.integerValidator]"
                outlined
                dense
                label="Quantity"
                placeholder="50"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-if="categoryChosen && !isAddOns && !categoryChosenPreorder"
                v-model="itemData.add_ons"
                :items="addOns"
                item-text="name_en"
                item-value="id"
                multiple
                class="mb-6"
                chips
                dense
                clearable
                persistent-hint
                outlined
                label="Select Add-ons"
                hint="You can select more than one"
              ></v-select>

              <v-text-field
                v-if="categoryChosenPreorder"
                v-model="itemData.remaining_hours"
                :rules="itemData.remaining_hours ? [validators.decimal_integer_Validator] : ['Min Value 1']"
                outlined
                dense
                label="reservation Time in Hours"
                hide-details="auto"
                placeholder="5 h"
                class="mb-6"
              ></v-text-field>

              <v-text-field
                v-if="categoryChosen && !itemData.size.length"
                outlined
                dense
                :rules="[validators.required]"
                disabled
                label="Price in Points"
                hide-details="auto"
                class="mb-6"
                type="number"
                :value="priceInPoints"
                readonly
              ></v-text-field>

              <v-file-input
                v-if="categoryChosen"
                v-model="itemData.image"
                accept=".jpg, .jpeg, .png"
                placeholder="Choose an image"
                outlined
                dense
                label="Upload Image"
                hide-details="auto"
                class="mb-6"
                prepend-icon=""
                persistent-hint
                hint="Desired dimensions: min-width: 833px; max-width: 1670px; min-height: 625px; max-height: 1253px; max-file-size: 500kb"
                :rules="[validators.required, fileAccept(['jpg', 'jpeg', 'png'], itemData.image)]"
              >
              </v-file-input>
              <v-card-text v-if="message.image" style="color: #ff3342; white-space: pre-wrap">
                {{ message.image[0] }}
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="6">
              <v-textarea
                v-if="categoryChosen && !isAddOns"
                v-model="itemData.description_en"
                :rules="[validators.textareaRule]"
                outlined
                dense
                :counter="250"
                label="Description (En)"
                placeholder="Mansaf"
                hide-details="auto"
                class="mb-6"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-textarea
                v-if="categoryChosen && !isAddOns"
                v-model="itemData.description_ar"
                :rules="[validators.textareaRule]"
                outlined
                dense
                :counter="250"
                label="Description (Ar)"
                placeholder="Mansaf"
                hide-details="auto"
                class="mb-6"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row
            class="d-flex align-items-baseline justify-content-center"
            v-if="categoryChosen && !isAddOns && !categoryChosenPreorder"
          >
            <span class="font-weight-medium me-2 p-2">Sizes:</span>
            <v-btn class="mx-2 mb-6" fab light color="indigo" @click="addSize">
              <v-icon dark>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </v-row>

          <v-row v-for="(item, i) in itemData.size" :key="item.id">
            <v-col cols="12" sm="3" class="my-0">
              <v-checkbox
                v-model="itemData.size[i].is_default"
                class="my-0"
                :rules="[validators.checkboxValidation(is_chosen_before_is_default, itemData.size[i].is_default)]"
                name="default"
                label="is default"
                @change="setDefualt(i)"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :rules="[validators.required]"
                v-model="itemData.size[i].name_en"
                :items="sizes"
                item-text="name_en"
                item-value="name_en"
                outlined
                dense
                clearable
                label="Size"
                @input="setSizeAr(i)"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="3">
              <v-text-field
                v-model="itemData.size[i].price"
                :rules="[validators.decimal_integer_Validator, itemData.size[i].calculateSizePointsMessage]"
                outlined
                dense
                label="Price (JOD)"
                @input="calculateSizePoints(i)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3">
              <v-text-field
                v-model="itemData.size[i].sizePriceInPoints"
                outlined
                dense
                disabled
                label="Price in (Points)"
                hide-details="auto"
                class="mb-6"
                type="number"
                :value="sizePriceInPoints[i]"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3">
              <v-text-field
                v-model="itemData.size[i].quantity"
                :rules="itemData.size[i].quantity == 0 ? ['min value 1'] : [validators.integerValidator]"
                outlined
                dense
                label="Quantity"
              ></v-text-field>
              <v-card-text v-if="message.for_row == i" style="color: #ff3342; white-space: pre-wrap">
                {{ message.item_size_errors.quantity[0] }}
              </v-card-text>
            </v-col>

            <v-col cols="12" sm="3">
              <v-text-field
                v-model="itemData.size[i].name_ar"
                disabled
                outlined
                dense
                label="Size Name (AR)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3">
              <v-file-input
                v-if="categoryChosen"
                v-model="itemData.size[i].image"
                accept=".jpg, .jpeg, .png"
                placeholder="Choose an image"
                outlined
                dense
                label="Image"
                hide-details="auto"
                class="mb-6"
                prepend-icon=""
                persistent-hint
                hint="Desired dimensions: min-width: 88px; max-width: 176px; min-height: 66px; max-height: 132px; max-file-size: 500kb"
                :rules="[validators.required, fileAccept(['jpg', 'jpeg', 'png'], itemData.size[i].image)]"
              >
              </v-file-input>
              <v-card-text v-if="message.for_row == i" style="color: #ff3342; white-space: pre-wrap">
                {{ message.item_size_errors.image[0] }}
              </v-card-text>
            </v-col>

            <v-col cols="12" sm="3">
              <v-btn @click="removeSize(item, 'size')">
                <v-icon>{{ icons.mdiDelete }}</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" sm="12">
              <v-divider class="mb-6"></v-divider>
            </v-col>
          </v-row>

          <v-row v-if="categoryChosenPreorder">
            <span class="font-weight-medium me-2 p-2">Components:</span>
            <v-btn class="mx-2 mb-6" fab light color="indigo" @click="addComponent">
              <v-icon dark>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </v-row>

          <v-row v-for="(item, i) in itemData.item_components" :key="i">
            <v-col cols="12" sm="5">
              <v-text-field
                v-model="itemData.item_components[i].name_en"
                outlined
                dense
                label="Name (En)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="5">
              <v-text-field
                v-model="itemData.item_components[i].name_ar"
                outlined
                dense
                label="Name (Ar)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-btn @click="removeSize(item, 'Components')">
                <v-icon>{{ icons.mdiDelete }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-card-text v-if="formMessage" style="color: #ff3342; white-space: pre-wrap">
            {{ formMessage }}
          </v-card-text>

          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetItemData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store'
import {
  checkboxValidation,
  decimal_integer_Validator,
  fileRequired,
  imageValidator,
  integerValidator,
  required,
  textareaRule,
} from '@core/utils/validation'
import { mdiClipboardFileOutline, mdiClose, mdiDelete, mdiPlus } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import { isString } from '@vueuse/shared'
import _ from 'lodash'
import Swal from 'sweetalert2'
import useItemsList from './useItemsList'

export default {
  model: {
    prop: 'isAddNewItemSidebarActive',
    event: 'update:is-add-new-item-sidebar-active',
  },
  props: {
    isAddNewItemSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankItemData = {
      selectedCategory: '',
      name_en: '',
      name_ar: '',
      category_id: '',
      chief_id: '',
      price: '',
      quantity: '',
      description_en: '',
      description_ar: '',
      image: null,
      add_ons: [],
      size: [],
      item_components: [],
      remaining_hours: 0,
    }

    const { fetchSizes } = useItemsList()
    const timer = ref(null)
    const categoryChosen = ref('')
    const categoryChosenMeal = ref(false)
    const categoryChosenPreorder = ref(false)
    const is_chosen_before_is_default = ref(false)
    const subCategories = ref([])
    const chefs = ref([])
    const addOns = ref([])
    const sizes = ref([])
    const isLoading = ref(false)
    const sizes_name = ref([])
    const priceInPoints = ref(0)
    const sizePriceInPoints = ref(0)
    const isAddOns = ref(false)
    const calculatePointsMessage = ref(true)
    const message = ref('')
    const formMessage = ref('')
    const categoriesOptions = [
      { title: 'Meal', value: '1' },
      { title: 'Pre Order', value: '2' },
    ]

    onMounted(() => {
      fetchSizes()
        .then(response => {
          sizes.value = response
          sizes_name.value = sizes.value.map(ele => {
            return ele.name_en
          })
          console.log('sizes.value', sizes.value)
        })
        .catch(error => console.error(error))
    })

    const isError = ref(false)
    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const itemData = ref(JSON.parse(JSON.stringify(blankItemData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankItemData))
      categoryChosen.value = false
      categoryChosenPreorder.value = false
      categoryChosenMeal.value = false
      isAddOns.value = false
      emit('update:is-add-new-item-sidebar-active', false)
    }

    const onSubmit = () => {
      if (itemData.value.size.length) {
        if (itemData.value.size.length < 2) {
          formMessage.value = 'You must add at least two sizes'
          return
        }
        if (itemData.value.size.every(ele => !ele.is_default)) {
          formMessage.value = 'Please Choose The Default Size'
          return
        }
      }

      if (valid.value) {
        formMessage.value = ''
        isLoading.value = true
        store
          .dispatch('app-items/addItem', itemData.value)
          .then(response => {
            isLoading.value = false
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: response.data.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
            fetchSizes()
              .then(response => {
                sizes.value = response
                sizes_name.value = sizes.value.map(ele => {
                  return ele.name_en
                })
                console.log('sizes.value', sizes.value)
              })
              .catch(error => console.error(error))

            message.value = ''
            resetItemData()
            emit('refetch-data')
          })
          .catch(error => {
            console.log(error.response.data)
            if (error.response?.data?.message) {
              isLoading.value = false
              console.log('error.response?.data?.message', error.response?.data?.message)
              message.value = error.response.data.message
            }
          })
      } else {
        formMessage.value = ''
        validate()
      }
    }

    const setCategory = () => {
      itemData.value.size = []
      itemData.value.item_components = []
      itemData.value.category_id = null
      categoryChosen.value = itemData.value.selectedCategory
      if (categoryChosen.value === '1') {
        categoryChosenMeal.value = true
        categoryChosenPreorder.value = false
        itemData.value.item_components = []
      } else if (categoryChosen.value === '2') {
        categoryChosenPreorder.value = true
        categoryChosenMeal.value = false
      } else {
        categoryChosenPreorder.value = false
        categoryChosenMeal.value = false
      }
      if (categoryChosen.value) {
        console.log('categoryChosen.value', categoryChosen.value)
        store
          .dispatch('app-items/fetchSubCategories', { parent_id: categoryChosen.value })
          .then(response => {
            subCategories.value = response.filteredData
            console.log('subCategories.value', subCategories.value)
          })
          .catch(error => {
            console.error(error)
          })

        store
          .dispatch('app-items/fetchChefs')
          .then(response => {
            chefs.value = response.data.data.filteredData
            console.log('chefs.value', chefs.value)
          })
          .catch(error => console.error(error))
      }
    }

    const subCatChange = () => {
      if (itemData.value.category_id !== null) {
        const selectedCat = subCategories.value.filter(item => item.id === itemData.value.category_id)

        if ((selectedCat[0] && selectedCat[0].add_ones == 1) || selectedCat[0].used_in_all_meals == 1) {
          isAddOns.value = true
          itemData.value.size = []
          itemData.value.item_components = []
        } else {
          isAddOns.value = false

          // Fetch Addons list
          store
            .dispatch('app-items/fetchAddons')
            .then(response => {
              addOns.value = response['add-ons']
            })
            .catch(error => console.error(error))

          // Fetch Sizes List
        }
      }
    }

    const calculatePoints = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(async () => {
        if (itemData.value.price <= 0 || !itemData.value.price || itemData.value.price > 999) {
          priceInPoints.value = 0
          calculatePointsMessage.value = 'MAX VALUE 999'
        } else {
          calculatePointsMessage.value = true
          await store
            .dispatch('app-items/getPriceInPoints', parseFloat(itemData.value.price))
            .then(response => {
              priceInPoints.value = response.data.data.price
            })
            .catch(error => console.error(error))
        }
      }, 1500)
    }

    const fileAccept = (accept, data) => {
      if (data && !isString(data)) {
        if (data.size > 500000) {
          return `MAX SIZE 500KB`
        }
        if (accept.includes(data.name.split('.')[1])) {
          return true
        } else {
          return `Please choose file path (${accept})`
        }
      }
      return true
    }

    const calculateSizePoints = i => {
      clearTimeout(timer.value)
      timer.value = setTimeout(async () => {
        if (itemData.value.size[i].price <= 0 || !itemData.value.size[i].price || itemData.value.size[i].price > 999) {
          sizePriceInPoints.value = 0
          itemData.value.size[i].sizePriceInPoints = 0
          itemData.value.size[i].calculateSizePointsMessage = 'MAX VALUE 999'
        } else {
          itemData.value.size[i].calculateSizePointsMessage = true
          store
            .dispatch('app-items/getPriceInPoints', itemData.value.size[i].price)
            .then(response => {
              sizePriceInPoints.value = response.data.data.price
              itemData.value.size[i].sizePriceInPoints = response.data.data.price
            })
            .catch(error => console.error(error))
        }
      }, 2000)
    }

    const setDefualt = i => {
      itemData.value.size[i].is_default === true
        ? (itemData.value.size[i].is_default = 1)
        : (itemData.value.size[i].is_default = 0)
      let filteredSize = []
      if (itemData.value.size.length > 1) {
        filteredSize = itemData.value.size.filter((item, idx) => idx == i)
        is_chosen_before_is_default.value = filteredSize.every((element, idx) => {
          return element.is_default == 1
        })
        console.log('is_chosen_before_is_default.value', is_chosen_before_is_default.value)
      }
      if (is_chosen_before_is_default.value) {
        itemData.value.size.forEach((element, idx) => {
          if (i != idx) {
            element.is_default = 0
          }
        })
      }
      itemData.value.price = itemData.value.size[i].price
    }

    const addSize = () => {
      const obj = {
        name_en: '',
        name_ar: '',
        price: '',
        size_id: '',
        quantity: '',
        image: null,
        calculateSizePointsMessage: true,
        is_default: 0,
      }

      itemData.value.size.push(obj)
    }

    const addComponent = () => {
      const obj = {
        name_en: '',
        name_ar: '',
      }

      itemData.value.item_components.push(obj)
    }

    const setSizeAr = i => {
      let sizeSelected = itemData.value.size.map((ele, i) => {
        return ele.name_en
      })
      console.log('sizeSelected', sizeSelected)
      console.log('_.difference(sizes.value,sizes_name.value)', _.difference(sizes_name.value, sizeSelected))

      const [selectedSize] = sizes.value.filter((size, idx) => {
        if (_.difference(sizes_name.value, sizeSelected).includes(size.name_en)) {
          size.disabled = false
        } else {
          size.disabled = true
        }
        return size.name_en === itemData.value.size[i].name_en
      })
      console.log(selectedSize)
      if (selectedSize) {
        itemData.value.size[i].name_ar = selectedSize.name_ar
        itemData.value.size[i].size_id = selectedSize.id
      } else {
        itemData.value.size[i].name_ar = null
        itemData.value.size[i].size_id = null
      }
    }

    const handleAler = () => {
      isError.value = false
    }

    const removeSize = (item, type) => {
      if (type == 'size') {
        itemData.value.size = itemData.value.size.filter(size => {
          if (size.size_id == item.size_id) {
            if (sizes.value[sizes.value.findIndex(ele => ele.id == item.size_id)]?.disabled) {
              sizes.value[sizes.value.findIndex(ele => ele.id == item.size_id)].disabled = false
            }
          }
          return size.size_id !== item.size_id
        })
      }
      if (type == 'Components') {
        itemData.value.item_components = itemData.value.item_components.filter(compo => compo !== item)
      }
    }

    return {
      isLoading,
      formMessage,
      message,
      is_chosen_before_is_default,
      fileAccept,
      resetItemData,
      form,
      onSubmit,
      itemData,
      valid,
      validate,
      isError,
      handleAler,
      categoriesOptions,
      categoryChosenMeal,
      categoryChosenPreorder,
      categoryChosen,
      subCategories,
      chefs,
      priceInPoints,
      sizePriceInPoints,
      isAddOns,
      blankItemData,
      addOns,
      sizes,
      subCatChange,
      setCategory,
      calculatePoints,
      calculatePointsMessage,
      addSize,
      addComponent,
      calculateSizePoints,
      setSizeAr,
      setDefualt,
      removeSize,

      // validation
      validators: {
        required,
        decimal_integer_Validator,
        imageValidator,
        fileRequired,
        checkboxValidation,
        integerValidator,
        textareaRule,
      },
      icons: {
        mdiClose,
        mdiClipboardFileOutline,
        mdiPlus,
        mdiDelete,
      },
    }
  },
}
</script>
