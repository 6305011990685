<template>
  <div id="items-list">
    <!-- app drawer -->
    <items-list-add-new v-model="isAddNewItemSidebarActive" @refetch-data="fetchItems"></items-list-add-new>

    <!-- items total card -->
    <v-row class="mb-5">
      <v-col v-for="total in itemsTotalLocal" :key="total.total" cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title> Search &amp; Filter </v-card-title>
      <v-divider class="mt-4"></v-divider>

      <!-- actions -->

      <v-row class="px-2 ma-0">
        <!-- plan filter -->
        <v-col cols="12" sm="4">
          <v-select
            v-model="planFilter"
            placeholder="Select Criteria"
            :items="planOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <!-- search -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="items-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" @click.stop="isAddNewItemSidebarActive = true">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Item</span>
          </v-btn>
          <v-btn color="secondary" outlined class="mb-4" @click="downLoadxlsx">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        id="table"
        v-model="selectedRows"
        :headers="tableColumns"
        :items="itemsListTable"
        :options.sync="options"
        :server-items-length="totalItemsListTable"
        :loading="loading"
        @refetch-data="fetchItems"
      >
        <!-- name EN -->
        <template #[`item.name_en`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-item-view', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name_en }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- chief_name -->
        <template #[`item.chief_name`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.chief_name ? item.chief_name : '___' }}</span>
          </div>
        </template>
        <!-- kitchen_name -->
        <template #[`item.kitchen_name`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.kitchen_name ? item.kitchen_name : '___' }}</span>
          </div>
        </template>
        
        <template #[`item.is_preorder`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.is_preorder ? 'Pre-Order ' : 'meal' }}</span>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-item-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiEye }}
                  </v-icon>
                  <span>View</span>
                </v-list-item-title>
              </v-list-item>

              <!-- <v-list-item >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiSquareEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item> -->

              <v-list-item link @click="handleDelete(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEye,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'
import ItemsStoreModule from '../itemsStoreModule'
import ItemsListAddNew from './ItemsListAddNew.vue'
import useItemsList from './useItemsList'
import { export_table_to_excel } from '@/toExcel'
export default {
  components: {
    ItemsListAddNew,
  },
  setup(props, { emit }) {
    const ITEMS_APP_STORE_MODULE_NAME = 'app-items'

    // Register module
    if (!store.hasModule(ITEMS_APP_STORE_MODULE_NAME)) {
      store.registerModule(ITEMS_APP_STORE_MODULE_NAME, ItemsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ITEMS_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEMS_APP_STORE_MODULE_NAME)
    })
    const {
      itemsListTable,
      tableColumns,
      searchQuery,
      totalItemsListTable,
      loading,
      options,
      itemsTotalLocal,
      selectedRows,
      planFilter,

      fetchItems,
    } = useItemsList()

    const isAddNewItemSidebarActive = ref(false)

    const planOptions = [
      { title: 'Name (EN)', value: 'name_en' },
      { title: 'Name (AR)', value: 'name_ar' },
      { title: 'Chef Name', value: 'Test' },
      { title: 'Meals', value: 'Test1' },
      { title: 'Pre Order', value: 'Test2' },
    ]

    const handleDelete = id => {
      Swal.fire({
        title: 'Are you sure you want to delete this item?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-items/deleteItem', id)
            .then(() => {
              itemsListTable.value = itemsListTable.value.filter(item => item.id !== id)
              Swal.fire('Deleted!', 'entry has been deleted.', 'success')
            })
            .catch(error => {
              console.error(error?.response?.data?.message)
              Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: error.response.data.message,
              })
            })
        }
      })
    }
    const downLoadxlsx = () => {
      export_table_to_excel('table', tableColumns, 'itemsList')
    }

    return {
      downLoadxlsx,
      itemsListTable,
      tableColumns,
      searchQuery,
      totalItemsListTable,
      loading,
      options,
      itemsTotalLocal,
      isAddNewItemSidebarActive,
      selectedRows,
      planOptions,
      planFilter,
      avatarText,
      fetchItems,
      handleDelete,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiEye,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
